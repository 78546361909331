@import 'app.scss';
.mediaContainer {
  position: relative;
}

.media {
  display: block;
  border-radius: var(--border-radius-extra-large);
  width: 100%;
  overflow: hidden;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.buttonContainer {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.button {
  @include umbrella-link;

  position: unset;
  border-radius: 50%;
  background-color: transparent;
  width: spacing(10);
  height: spacing(10);
  color: var(--color-fg-on-interactive);

  &:hover,
  &:focus {
    background-color: transparent;
    color: var(--color-bg-primary-cta-hover);
  }
}
