@import 'app.scss';
/**
 * Image section
 */

/**
 * ======================================
 * Elements
 * ======================================
 */
.imageContainer {
  border-radius: var(--border-radius-extra-large);
  overflow: hidden;

  @include from-breakpoint(lg) {
    span {
      aspect-ratio: 16/6;
    }
  }
}

.image {
  object-fit: cover;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.hasContent {
  margin-bottom: spacing(4) !important;
}
