@import 'app.scss';
/**
 * Carousel
 */

/**
 * ======================================
 * Elements
 * ======================================
 */
.container {
  display: flex;
  position: relative;
  margin-bottom: spacing(4);
}

.swiper {
  margin-bottom: spacing(2);
  overflow: visible;
}

.controls {
  display: none;

  @include from-breakpoint(md) {
    display: block;
    margin-left: auto;
  }
}

.navigationContainer {
  display: flex;
  gap: spacing(1);
}

.navigation {
  display: flex;
  justify-content: center;
  border: 1px solid var(--color-fg-on-default);
  border-radius: 50%;
  padding: spacing(2);
  width: spacing(6);
  height: spacing(6);

  &:disabled {
    border: 1px solid var(--color-border-default);
  }

  &:not([disabled]) {
    @include wave-animation(
      var(--color-fg-on-default),
      var(--color-fg-on-primary-brand)
    );
  }
}

.scrollbar {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #fff;
  border-radius: var(--border-radius-base);
  width: calc(var(--bar-width) + 2.5313rem);
  height: spacing(1);
  overflow: hidden;

  &::before {
    position: absolute;
    opacity: 0.2;
    z-index: 0;
    border-radius: var(--border-radius-base);
    background-color: var(--color-fg-on-default);
    width: 100%;
    height: 100%;
    content: '';
  }

  &::after {
    display: block;
    position: absolute;
    z-index: 2;
    background: repeating-linear-gradient(
      90deg,
      transparent,
      transparent spacing(4),
      #fff spacing(4),
      #fff 2.625rem
    ); // 2.625rem = 42px

    width: 100%;
    height: 100%;
    content: '';
  }

  @include from-breakpoint(sm) {
    width: var(--bar-width);
  }
}

.scrollbarProgress {
  position: absolute;
  transition: var(--transition-base);
  z-index: 1;
  border-radius: var(--border-radius-base);
  background-color: var(--color-fg-on-default);
  width: var(--bar-width);
  height: 100%;
}
