@import 'app.scss';
/**
 * Banner section
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.container {
  margin: auto;
  max-width: 59.875rem;
  text-align: center;
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.title {
  @include typography-heading-3;

  @include from-breakpoint(md) {
    @include typography-heading-2;
  }
}

.subtitle {
  @include typography-quote;

  margin-bottom: 0;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.showTitleSecond {
  display: grid;

  .title {
    order: 1;
  }
}
