@import 'app.scss';
/**
 * Faq section
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.container {
  margin-bottom: 4.5rem;

  @include from-breakpoint(lg) {
    margin-bottom: 0;
    border-radius: var(--border-radius-extra-large);
    background-color: var(--color-bg-default);
    padding: spacing(10);
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.content {
  margin-bottom: spacing(3);

  @include from-breakpoint(lg) {
    margin-bottom: spacing(4);
  }
}

.ctaButton {
  margin-top: spacing(4);
  overflow: hidden;

  @include from-breakpoint(lg) {
    margin-top: spacing(6);
  }
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.container.white {
  @include from-breakpoint(lg) {
    background-color: var(--color-bg-alternative);
  }
}
