@import 'app.scss';
.figure {
  margin-bottom: 0;
  border-radius: var(--border-radius-extra-large);
  overflow: hidden;
}

.clipPath {
  position: absolute;
  width: 0;
  height: 0;
}

.noBorder {
  border-radius: 0;
}
