@import 'app.scss';
/**
 * Grid Item
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.item {
  display: flex;
  position: relative;
  flex-direction: column;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.title {
  margin-bottom: spacing(1);
}

.link {
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

.subtitle {
  display: block;
  margin-bottom: spacing(1);
  font-size: 1rem;

  @include from-breakpoint(lg) {
    font-size: 1.25rem;
  }
}

.description,
.children {
  margin-bottom: spacing(2);
}

.tags {
  order: -1;
}

.metaList {
  display: grid;
  grid-template-columns: auto;
  gap: spacing(1);
}

.metaItem {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
}

.imageContainer {
  position: relative;
  order: -2;
  margin-bottom: spacing(2);
  border-radius: var(--border-radius-extra-large);
  overflow: hidden;
  aspect-ratio: 1.34;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.item.locationItem {
  .tags {
    order: unset;
    margin-bottom: 0;
  }
}

.item.eventItem,
.item.iframeItem {
  .subtitle {
    font-weight: bold;
    font-style: 1rem;
  }
}

.item.imageSliderItem {
  .title {
    font-weight: 400;
  }
}

.item.iframeItem {
  .title,
  .subtitle {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .description {
    display: -webkit-box;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .meta {
    margin-top: auto;
  }
}
