@import 'app.scss';
/**
 * JobAlertCardB
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.card {
  display: grid;
  border-radius: var(--border-radius-extra-large);
  box-shadow: var(--shadow-tile);
  background-color: var(--color-bg-default);
  padding: spacing(3);
  width: 100%;

  @include from-breakpoint(lg) {
    padding: spacing(4);
    height: 100%;
  }

  @include from-breakpoint(xl) {
    padding: spacing(6);
  }
}

.contentContainer {
  margin-bottom: spacing(3);
  max-width: var(--content-width);
}

.title {
  margin-bottom: spacing(2);
}

.text {
  margin-bottom: 0;
}

.contactContainer {
  margin-top: spacing(3);
  width: 100%;

  @include from-breakpoint(sm) {
    display: flex;
  }
}

.imageContainer {
  position: relative;
  flex-shrink: 0;
  margin-bottom: spacing(2);
  border-radius: var(--border-radius-large);
  width: 6.5625rem; // 105px
  height: 6.5625rem; // 105px
  overflow: hidden;

  span {
    display: block;
    width: 100%;
    height: 100%;
  }

  @include from-breakpoint(sm) {
    margin-right: spacing(3);
    margin-bottom: 0;
  }
}

.image {
  display: block;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.name {
  margin-bottom: spacing(1);
}

.links {
  display: grid;
  gap: spacing(1);
  word-break: break-word;
}

.link {
  display: flex;
  gap: spacing(1);
  align-items: center;
  text-decoration: none;
  font-weight: 700;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.buttonContainer {
  display: flex;
  flex-flow: row wrap;
  gap: spacing(2);
  margin-top: auto;

  @include from-breakpoint(xl) {
    flex-shrink: 1;
  }
}

.button {
  width: max-content;
}

.whiteButton {
  background-color: var(--color-bg-default);
  width: max-content;
  color: var(--color-fg-on-primary-cta);

  &:hover,
  &:focus-visible {
    color: var(--color-fg-on-primary-cta);
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.horizontalCard {
  display: grid;
  align-items: flex-start;
  background-color: var(--color-bg-primary-cta);
  padding-right: spacing(7);
  width: 100%;

  .links {
    display: flex;
    flex-direction: column;
    gap: spacing(1);
    word-break: break-word;
  }

  @include from-breakpoint(md) {
    .links {
      flex-flow: row wrap;
    }
  }

  @include from-breakpoint(xl) {
    grid-template-columns: auto auto;
    gap: spacing(3) spacing(6);

    .links {
      gap: spacing(1) spacing(4);
    }

    .buttonContainer {
      justify-content: flex-end;
      margin-top: 0;
    }
  }
}

.alternativeShadow {
  box-shadow: var(--shadow-tile-alternative);
}
