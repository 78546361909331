@import 'app.scss';
.figure {
  position: relative;
  margin-bottom: 0;
  border-radius: var(--border-radius-extra-large);
  overflow: hidden;
  aspect-ratio: 1.25;
}

.image {
  clip-path: url('#clip-path');
}

.showImageAsBlobFromLg {
  clip-path: none;

  @include from-breakpoint(lg) {
    clip-path: url('#clip-path');
  }
}

.clipPath {
  position: absolute;
  width: 0;
  height: 0;
}

.noBorder {
  border-radius: 0;
}
