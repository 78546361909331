@import 'app.scss';
$layout-breakpoint: 'lg';

.container {
  row-gap: spacing(4);
}

.content {
  display: flex;
  grid-column: 1 / -1;
  align-items: center;

  @include from-breakpoint($layout-breakpoint) {
    grid-column: 1 / span 6;
  }
}

.media {
  grid-column: 1 / -1;

  @include from-breakpoint($layout-breakpoint) {
    grid-column: auto / span 6;
  }
}

/* Modifiers */
.container.textRight {
  @include from-breakpoint($layout-breakpoint) {
    .content {
      grid-column: auto / span 6;
    }

    .media {
      grid-row: 1;
      grid-column: 1 / span 6;
    }
  }

  @include from-breakpoint(xl) {
    .content {
      grid-column: auto / span 6;
    }
  }
}

.container.zorgkaart {
  .media {
    display: flex;
    justify-content: center;
    order: -1;

    @include from-breakpoint($layout-breakpoint) {
      order: 0; // default
    }
  }
}
