@import 'app.scss';
.container {
  display: grid;
  gap: spacing(2);

  @include from-breakpoint(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include from-breakpoint(xl) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.card {
  border-radius: var(--border-radius-extra-large);
  box-shadow: var(--shadow-tile);
  background-color: var(--color-bg-default);
  padding: spacing(3);
  width: 100%;

  .alternativeShadow & {
    // Custom shadow here because of the overflow hidden on the sections which cuts of the top of the shadows
    box-shadow: 0 1rem 1.5rem 0.5rem rgb(0 0 0 / 0.05);
  }

  @include from-breakpoint(lg) {
    padding: spacing(4);
    height: 100%;
  }
}

.title {
  hyphens: auto;
  margin-bottom: 0;
}

.text,
.links {
  margin-top: spacing(2);
}

.links {
  display: grid;
  gap: spacing(1);
  word-break: break-word;
}

.button {
  margin-top: spacing(3);
}
