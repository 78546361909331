@import 'app.scss';
/**
 * Tag
 */

/**
 * ======================================
 * Elements
 * ======================================
 */
.tag {
  display: inline-block;
  border: 1px solid var(--color-fg-on-default);
  border-radius: spacing(4); // fully rounded corners
  background-color: var(--color-bg-default);
  padding: spacing(1) spacing(2);
  line-height: 1.2;
  color: inherit;
  font-size: 0.875rem;
  font-weight: 700;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.tag.highlighted {
  background-color: var(--color-bg-primary-cta);
  color: var(--color-fg-on-primary-cta);
}
