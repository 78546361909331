@import 'app.scss';
/**
 * Address
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.address {
  position: relative;
  margin-bottom: 0;
  line-height: 1.4;
  font-weight: 400;
  font-style: normal;
}

/**
* ======================================
* Elements
* ======================================
*/

.icon {
  position: absolute;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.pin {
  display: none;
  top: 0.25rem;
}

.messageIcon {
  top: 0.125rem;
}

.link {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.message {
  position: relative;
  margin-top: spacing(1);
  margin-bottom: 0;
  padding-left: spacing(4);
}

/**
* ======================================
* Modifiers
* ======================================
*/

.address.withIcon {
  padding-left: spacing(4);
  font-weight: 700;

  .pin {
    display: block;
  }
}

.address.applicationLocation {
  font-weight: 400;
}

.address.locationItem {
  .spacer {
    display: block;
    height: 1.75rem; // 28px, the same height as if there were a textline
  }
}
