@import 'app.scss';
/**
 * Meta
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.meta {
  display: grid;
  grid-template-columns: auto;
  gap: spacing(2);

  @include from-breakpoint(sm) {
    grid-template-columns: repeat(2, auto);
  }
}

/**
 * ======================================
 * Elements
 * ======================================
 */
.metaItem {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
}

.contact {
  @include typography-heading-5;

  display: flex;
  gap: 0.625rem;
  align-items: center;
  margin-bottom: 0;
  font-weight: 700;
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */

.metaItem.contactItem {
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}

.metaItem.fullWidth {
  @include from-breakpoint(sm) {
    grid-column: 1 / span 2;
  }
}
