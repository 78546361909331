@import 'app.scss';
.faqItem {
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-black);

    .showText {
      margin-bottom: spacing(3);
    }
  }
}

.header {
  width: 100%;
}

.text {
  display: none;
  margin-left: 0;
}

.showText {
  display: block;
}

.accordionButton {
  @include typography-button;

  display: flex;
  gap: spacing(1);
  align-items: center;
  justify-content: space-between;
  padding: spacing(2) 0;
  width: 100%;
  text-align: left;

  &[aria-expanded='true'] {
    .iconContainer {
      transform: rotate(0);
    }
  }

  .iconContainer {
    transform: rotate(180deg);
  }
}

.chevron {
  flex-shrink: 0;
  transform: rotate(180deg);
  transition: transform var(--transition-base);
  width: spacing(2);
  height: spacing(2);

  [aria-expanded='true'] & {
    transform: rotate(0deg);
  }
}

.ctaButton {
  margin-top: spacing(3);
}
